<template>
  <div v-frag>
    <div
      class="text-body-2"
      v-text="$t(title)"
      v-if="!hideTitle && title.length"
    />
    <name-with-avatar :items="items">
      <template #subtitle>
        <slot />
      </template>
    </name-with-avatar>
  </div>
</template>
<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import NameWithAvatar from "@/components/common/NameWithAvatar.vue";
import { AppModule } from "@/store/app";
import type { CompanyData } from "@planetadeleste/vue-mc-gw";
import { Company } from "@planetadeleste/vue-mc-gw";

@Component({
  components: {
    NameWithAvatar,
  },
})
export default class CompanyPreview extends Vue {
  @Prop({ type: String, default: "company" }) readonly title!: string;
  @Prop(Boolean) readonly hideTitle!: boolean;
  @Prop(Object) readonly company!: CompanyData | Company;
  @Prop(Array) readonly companies!: CompanyData[];

  mapData(obItem: CompanyData) {
    return this.$_.assign(obItem, {
      path: obItem.preview_image,
      name: obItem.firm.name,
    });
  }

  get obCompany() {
    return this.company
      ? this.company instanceof Company
        ? this.company.attributes
        : this.company
      : AppModule.company.attributes;
  }

  get items() {
    const arItems: Partial<CompanyData>[] = this.companies || [this.obCompany];
    return this.$_.map(arItems, this.mapData);
  }
}
</script>
