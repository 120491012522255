<template>
  <component
    :is="tag"
    v-bind="wrapOptions"
    v-if="company.branches.length > 1 || customerId"
  >
    <div v-if="!select" class="text-body-2" v-text="$t(label)" />

    <template v-if="select">
      <v-select
        v-model="branchListID"
        :items="branchList"
        class="mt-0"
        item-text="firm.name"
        item-value="id"
        v-bind="obAttrs"
        @change="onSelect"
        @click:clear="onClear"
      >
        <template #selection="{ item }">
          <branch-item-preview :item="item" hide-icon hide-default-icon dense />
        </template>

        <template #item="{ item, on }">
          <branch-item-preview
            :item="item"
            v-on="on"
            hide-icon
            hide-default-icon
            dense
          />
        </template>
      </v-select>
    </template>

    <template v-else-if="multiple">
      <template v-for="(branchItem, index) in branchList">
        <v-checkbox
          :key="`prd-branch-${branchItem.id}`"
          v-model="branchListID"
          :class="{ 'mt-0': index === 0 }"
          :disabled="branchList.length === 1"
          :label="branchItem.firm.name"
          :value="branchItem.id"
          hide-details
        ></v-checkbox>
      </template>
    </template>

    <v-radio-group v-else v-model="branchListID">
      <template v-for="(branchItem, index) in branchList">
        <v-radio
          :key="`prd-branch-${branchItem.id}`"
          :class="{ 'mt-0': index === 0 }"
          :disabled="branchList.length === 1"
          :label="branchItem.firm.name"
          :value="branchItem.id"
          hide-details
        ></v-radio>
      </template>
    </v-radio-group>
  </component>
</template>

<script lang="ts">
import { AppModule } from "@/store/app";
import { Component, Mixins, Prop, VModel, Watch } from "vue-property-decorator";
import type { BranchData } from "@planetadeleste/vue-mc-gw";
import { BranchCollection } from "@planetadeleste/vue-mc-gw";
import BranchItemPreview from "@/modules/companies/components/BranchItemPreview.vue";
import { find, isEmpty, isNil } from "lodash";
import SelectFieldMixin from "@/mixins/SelectFieldMixin";

@Component({
  components: { BranchItemPreview },
})
export default class BranchSelect extends Mixins(SelectFieldMixin) {
  @VModel({ type: [Number, Array] }) branchListID!: number;
  @Prop(Boolean) readonly multiple!: boolean;
  @Prop(Boolean) readonly select!: boolean;
  @Prop({ type: String, default: "div" }) readonly tag!: string;
  @Prop({ type: Object, default: () => ({}) }) readonly wrapOptions!: Record<
    string,
    any
  >;
  @Prop([Number, String]) readonly customerId!: number;
  @Prop(Boolean) readonly clearable!: boolean;
  @Prop(Boolean) readonly disabled!: boolean;

  obBranches: BranchCollection = new BranchCollection();
  sLabelDefault = "branches";

  get company() {
    return AppModule.company;
  }

  get branch() {
    return AppModule.branch;
  }
  get branchList() {
    if (this.customerId) {
      return this.obBranches.getModelList() as BranchData[];
    }

    if (
      !this.company ||
      !this.company.branches ||
      !this.company.branches.length
    ) {
      return [];
    }

    return this.company.branches;
  }

  get multi() {
    return this.multiple && !this.select;
  }

  @Watch("customerId")
  async loadBranches() {
    if (!this.customerId) {
      return;
    }

    this.obBranches.clear();
    this.obBranches.filterBy({ customer: this.customerId });

    await this.obBranches.page(1).fetch();

    this.obBranches.sort((obBranch) => {
      return obBranch.is_default ? 1 : 99;
    });
  }

  onSelect(sValue: number) {
    const obData = find(this.branchList, { id: sValue });
    this.$emit("change", obData);
  }

  onClear() {
    const branchListID = this.multiple ? [] : undefined;
    this.$emit("input", branchListID);
    this.$emit("clear");
  }

  async mounted() {
    await this.loadBranches();

    if (this.disabled) {
      return;
    }

    if (
      this.branch &&
      (isEmpty(this.branchListID) || isNil(this.branchListID))
    ) {
      const branchListID = this.multiple ? [this.branch.id] : this.branch.id;
      this.$emit("input", branchListID);
    }
  }
}
</script>
